@font-face {
  font-family: 'Lato-Thin';
  src: url('./../fonts/Lato-Thin.ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Light';
  src: url('./../fonts/Lato-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('./../fonts/Lato-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('./../fonts/Lato-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('./../fonts/Lato-Black.ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
