// COLORS
$primary-pure: #000000;
$primary-medium: #535353;
$primary-light: #929292;

$secondary-pure: #4CCAAC;
$secondary-light: #D3F2EA;
$secondary-medium: #349277;
$secondary-dark: #1E4F42;

$gradient: linear-gradient(90deg, #DBDFA8 0%, #8DDDCB 87.67%);

$highlight-pure: #F7366E;
$highlight-light: #FCD2E0;
$highlight-medium: #BC2B55;
$highlight-dark: #761D37;

$low-pure: #000000;
$low-light: #A3A3A3;
$low-medium: #666666;
$low-dark: #292929;

$high-pure: #FFFFFF;
$high-light: #F5F5F5;
$high-medium: #E0E0E0;
$high-dark: #CCCCCC;

$warning-pure: #E30613;
$warning-light: #FBCCCC;
$warning-medium: #B6050F;
$warning-dark: #480009;

$helper-pure: #F8A529;
$helper-light: #FEEDD4;
$helper-medium: #C68421;
$helper-dark: #634210;

$high-light: #FAFAFA;
$high-darker: #64748B;