@import 'variables';

body {
  margin: 0;
  font: 14px 'Lato-Regular', sans-serif;
  background: #f6f7fb;
}

strong {
  font: 1rem 'Lato-Bold', sans-serif;
}

h1 {
  font: 2rem 'Lato-Bold', sans-serif;
  color: $primary-pure;
}

h1 {
  font: 1.6rem 'Lato-Bold', sans-serif;
  color: $primary-pure;
}

p {
  font: 1.0rem 'Lato-Regular', sans-serif;
}
